import React from 'react';
import styles from '../../sass/components/Portfolio-Parts/Introduction.module.scss';
import { VscCode, VscTerminalCmd, VscVmActive, VscGraph, VscAccount } from "react-icons/vsc";
import { Column } from '../Global';

function Introduction(){
    return (
        <section className={styles.intro} id="expertise">
            <Column gap={1}>
                <h5>- Introduction</h5>
                <p>A highly motivated and creative software developer based in London with a strong focus on ReactJS and React Native. I am passionate about technology products that feature efficient, intuitive, and reusable code and constantly strive to find innovative solutions to development challenges.</p>
            </Column>
            <div className={styles.icon}><VscAccount /></div>
            <Column gap={1}>
                <h5>- Front-end development </h5>
                <p>Experienced leading projects and specializing in delivering highly scalable, visually stunning, and innovative web-based solutions. Proficient in HTML5, JSX, CSS/SASS, JavaScript/TypeScript, PHP, ReactJS/NextJS, React Native, GraphQL, WordPress, and Git, I have a solid understanding of the full development lifecycle. Passionate about programming, I focus on creating efficient, intuitive, and reusable code that enhances user experience and drives business growth.</p>
            </Column>
            <div className={styles.icon}><VscCode /></div>
            <Column gap={1}>
                <h5>- Back-end devlopement</h5>
                <p>As a full-stack developer with extensive commercial experience, I have a solid foundational knowledge of back-end technologies that enhances my ability to create seamless full-stack applications. Proficient in Node.js, Express, MongoDB, MySQL, and Firebase, I am capable of developing and integrating APIs, working with databases, and ensuring that front-end components interact smoothly with back-end services. This complementary skill set allows me to collaborate effectively with back-end teams and contribute to creating robust, dynamic web applications.</p>
            </Column>
            <div className={styles.icon}><VscTerminalCmd /></div>
            <Column gap={1}>
                <h5>- UI/UX Design </h5>
                <p>As a design-focused developer, I specialize in creating hyper-modern solutions that seamlessly integrate development with brand design. With expertise in modern design tools like Adobe XD and Figma, I deliver custom, effective outcomes tailored to each project's specific needs, enhancing user engagement and satisfaction.</p>
            </Column>
            <div className={styles.icon}><VscVmActive /></div>
            <Column gap={1}>
                <h5>- SEO / Optimisation</h5>
                <p>A skilled digital marketer with a proven track record in implementing effective SEO strategies. With hands-on experience achieving top Google rankings for competitive search terms, I excel in driving traffic and boosting online visibility for businesses through best-practice SEO techniques and continuous optimization.</p>
            </Column>
            <div className={styles.icon}><VscGraph /></div>
        </section>
    )
}

export default Introduction;